import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { auth } from "../components/Firebase/Firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import Layout from "../components/Layout";
import View from "../components/View";
import * as Styles from '../styles/index.module.scss'

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(`/`);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Layout  >
      <View title="Bitte bestätigen Sie vor dem Log-In Ihre E-Mailadresse">
        
      <div className={Styles.instructions}>
        <p>In Ihrem Postfach (oder Spam Ordner) ist eine E-Mail zum Bestätigen Ihrer Anmeldung an unserem Lernportal. Bitte </p>
        <div className="login">
          <div className="login__container">
            {error && <div className="auth__error">{error}</div>}
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
              />
              <input
                type="password"
                name="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <button tabIndex="0">Login</button>
            </form>

            <h4>Weitere Aktionen</h4>
            <ul>
              <li>
                <Link to="/reset">Kennwort zurücksetzen</Link>
              </li>
              <li><Link to="/register">Auf der Website Registrieren</Link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </View>
    </Layout>
  );
};

export default Login;